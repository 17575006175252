import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import QuotesContext from '../QuotesContext';
import supabase from '../supabaseClient';
import { MusicContext } from '../MusicContext';
import './QuoteList.css';
import previous from './previous.png';
import next from './next.png';
import airdropinfo from './airdropinfo.png';
import Socials from './Socials';
import AnimatedCounter from './AnimatedCounter';
import twerror from './wallet.png';

const Ranking = ({ onEnter, showWelcome, setShowWelcome }) => {
  const { quotes, setQuotes } = useContext(QuotesContext);
  const { togglePlayPause } = useContext(MusicContext);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('most_voted');
  const [bounceOut, setBounceOut] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [totalPages, setTotalPages] = useState(1);
  const [votedQuotes, setVotedQuotes] = useState({});
  const [modalContent, setModalContent] = useState(null);
  const [showThanksBox, setShowThanksBox] = useState({});
  const [mostVotedQuotes, setMostVotedQuotes] = useState([]);
  const [topContributors, setTopContributors] = useState([]);
  const [totalWallets, setTotalWallets] = useState(0);

  const itemsPerPage = 15; // Items a mostrar por página

  const adminAddresses = process.env.REACT_APP_ADMIN_ADDRESSES.split(',').map(addr => addr.trim().toLowerCase());

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ip = response.data.ip;
        setIpAddress(ip);

        const storedVotedQuotes = JSON.parse(localStorage.getItem(ip) || '{}');
        setVotedQuotes(storedVotedQuotes);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchTopUsers = async () => {
    setLoading(true);
    try {
      // Obtener el total de wallets registradas
      const { count: totalWallets, error: totalCountError } = await supabase
        .from('user_stats')
        .select('wallet_address', { count: 'exact', head: true });
  
      if (totalCountError) {
        throw totalCountError;
      }
  
      // Obtener los top 100 usuarios con sus puntos totales
      const { data: topUsers, error: topUsersError } = await supabase
        .from('user_stats')
        .select('wallet_address, total_points')
        .order('total_points', { ascending: false })
        .limit(100);
  
      if (topUsersError) {
        throw topUsersError;
      }
  
      // Obtener datos adicionales de los usuarios
      const walletAddresses = topUsers.map(user => user.wallet_address);
      const { data: usersData, error: usersError } = await supabase
        .from('users')
        .select('wallet_address, twitter_username, profile_image')
        .in('wallet_address', walletAddresses);
  
      if (usersError) {
        throw usersError;
      }
  
      // Combinar los datos obtenidos
      const combinedData = topUsers
        .map(user => {
          const userData = usersData.find(u => u.wallet_address === user.wallet_address) || {};
          return {
            ...user,
            ...userData,
          };
        })
        .filter(user => !adminAddresses.includes(user.wallet_address.toLowerCase()) 
                        && user.total_points > 0 
                        && ((user.wallet_address && user.wallet_address.startsWith('0x')) 
                            || (user.twitter_username && user.twitter_username.trim() !== ''))); // Filtrar usuarios sin wallet_address válida o twitter_username
  
      // Actualizar el estado con los datos procesados
      setTopContributors(combinedData);
      setTotalPages(Math.ceil(combinedData.length / itemsPerPage));
      setTotalWallets(totalWallets);
    } catch (error) {
      console.error('Error fetching top users:', error.message);
    } finally {
      setLoading(false); // Asegúrate de establecer `loading` a `false` después de que los datos se hayan obtenido
    }
  };
  
  useEffect(() => {
    fetchTopUsers();
  }, []);

  const handleEnterClick = () => {
    setBounceOut(true);
    setTimeout(() => {
      setShowWelcome(false);
      togglePlayPause();
      if (onEnter) {
        onEnter();
      }
    }, 1000);
  };

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="main-container">
      {showWelcome ? (
        <div className={`welcome-container ${bounceOut ? 'bounce-out' : ''}`}>
          <button onClick={handleEnterClick}>
            <img id="hover08" draggable="false" src={isMobile ? "/intro-mobile.png" : "/intro.png"} alt="intro" className="intro-image" />
          </button>
        </div>
      ) : (
        <>
          <div className="text-carousel-wrapper" style={{ background: '#ddbb24' }}>
            <h1 className="text-6xl font-bold text-white centered-title carousel-title">
              {isMobile ? 'My Fucking Cryptos' : 'My Fucking Cryptos Ranking'}
            </h1>
  
            <Socials />
  
            <div className="text-carousel text-align-center carousel-subtitle">
              <h3 className="text-4xl font-bold text-white">
                If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
              </h3>
            </div>
          </div>
  
          <div className="main-container">
            <div className="referrals-table-container">
              <h2 className="text-3xl font-bold text-black centered-title md:text-4xl" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>MFC Airdrop Ranking
                <a href="https://docs.myfuckingcryptos.com/airdrop-mfer" target="_blank" rel="noopener noreferrer">
                  <img draggable="false" src={airdropinfo} alt="info" title='More Info' style={{width:'30px', height:'25px', marginLeft:'0.35em'}}/>
                </a>
              </h2>
              <p className="text-2xl text-black centered-title">Total Registered Mfers: <AnimatedCounter target={totalWallets} /> </p>

              <div className="referrals-table-wrapper">
                <table className="referrals-table" style={{ border: '2px solid black' }}>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#ddbb24' }}>TOP User / Wallet</th>
                      <th style={{ backgroundColor: '#ddbb24' }}>Total Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      // Mostrar placeholders o filas de esqueleto mientras los datos se cargan
                      Array.from({ length: itemsPerPage }).map((_, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center', padding: '10px' }}>Loading Mfer...</td>
                          <td style={{ textAlign: 'center', padding: '10px' }}>Loading Points...</td>
                        </tr>
                      ))
                    ) : (
                      topContributors
                        .filter(user => (user.wallet_address && user.wallet_address.startsWith('0x')) 
                                    || (user.twitter_username && user.twitter_username.trim() !== '')) // Filtrar usuarios válidos
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((user, index) => {
                          const isTopThree = ((page - 1) * itemsPerPage + index) < 3;
                          const showWalletAddress = user.twitter_username === 'My Fucking Cryptos';
                          return (
                            <tr key={user.wallet_address} style={isTopThree ? { fontWeight: 'bold' } : {}}>
                              <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.1em' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                  <span style={{ marginRight: '0.5em' }}>
                                    {((page - 1) * itemsPerPage + index) === 0 ? '🥇' : ((page - 1) * itemsPerPage + index) === 1 ? '🥈' : ((page - 1) * itemsPerPage + index) === 2 ? '🥉' : ''}
                                  </span>
                                  {user.twitter_username && !showWalletAddress ? (
                                    <>
                                      <img
                                        src={user.profile_image}
                                        alt={user.twitter_username}
                                        className="profile-image"
                                        style={{ width: '28px', height: '28px', borderRadius: '50%', marginRight: '0.5em' }}
                                        onError={(e) => e.target.src = twerror}
                                      />
                                      {user.twitter_username}
                                    </>
                                  ) : (
                                    shortenAddress(user.wallet_address)
                                  )}
                                </div>
                              </td>
                              <td style={{ textAlign: 'center', fontSize: '1.1em' }}>
                                <AnimatedCounter target={user.total_points} />
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>

              <div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
                {page > 1 && (
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    className="page-button"
                    style={{ margin: '0 0.5em' }}
                  >
                    <img draggable="false" src={previous} alt="previous" />
                  </button>
                )}
                {page < totalPages && (
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    className="page-button"
                    style={{ margin: '0 0.5em' }}
                  >
                    <img draggable="false" src={next} alt="next" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
  
} 

export default Ranking;
