import React, { useEffect, useState } from 'react';
import supabase from '../supabaseClient';
import { FaCheck, FaTimes, FaBolt, FaGift  } from 'react-icons/fa';
import { useAddress } from '@thirdweb-dev/react';
//import publishToTwitter from './publishToTwitter';
import wallet from './wallet.png';

const AdminQuoteList = () => {
  const [quotes, setQuotes] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [pendingQuotes, setPendingQuotes] = useState([]);
  const [pendingCount, setPendingCount] = useState(0); // New state for pending count
  const address = useAddress();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const ADMIN_ADDRESSES = process.env.REACT_APP_ADMIN_ADDRESSES
    ? process.env.REACT_APP_ADMIN_ADDRESSES.split(',').map(addr => addr.trim().toLowerCase())
    : [];

  //const ENABLE_TWITTER = process.env.REACT_APP_ENABLE_TWITTER === 'true';
  //const ENABLE_QUEUE = process.env.REACT_APP_ENABLE_QUEUE === 'true';
  const SUBMISSION_LIMIT_HOURS = parseInt(process.env.REACT_APP_SUBMISSION_LIMIT_HOURS, 10);
  const SUBMISSION_LIMIT_COUNT = parseInt(process.env.REACT_APP_SUBMISSION_LIMIT_COUNT, 10);

  useEffect(() => {
    fetchPendingQuotes();
    fetchQueueQuotes();
    fetchPendingQuoteCount(); // Fetch pending count on mount
  }, []);

  const fetchPendingQuoteCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pending-quote-count`);
      const result = await response.json();
      if (result.success) {
        setPendingCount(result.count);
      } else {
        console.error('Error fetching pending quote count:', result.error);
      }
    } catch (error) {
      console.error('Unexpected error fetching pending quote count:', error);
    }
  };

  const isLengthValid = (text) => {
    const minLength = 50;
    return text.length >= minLength;
  };

  const hasExceededSubmissionLimit = async (userAddress) => {
    if (ADMIN_ADDRESSES.includes(userAddress.toLowerCase())) {
      return false;
    }

    const { data: recentSubmissions, error } = await supabase
      .from('quotes')
      .select('*')
      .eq('author', userAddress)
      .gte('created_at', new Date(Date.now() - SUBMISSION_LIMIT_HOURS * 60 * 60 * 1000).toISOString());

    if (error) {
      console.error('Error fetching recent submissions:', error.message);
      return false;
    }

    return recentSubmissions.length >= SUBMISSION_LIMIT_COUNT;
  };

  const fetchPendingQuotes = async () => {
    try {
      const { data: quotesData, error: quotesError } = await supabase
        .from('quotes')
        .select('*')
        .eq('approved', false);
  
      if (quotesError) {
        throw new Error(quotesError.message);
      }
  
      console.log("Quotes pending approval:", quotesData);
  
      const { data: approvedQuotesData, error: approvedQuotesError } = await supabase
        .from('quotes')
        .select('text')
        .eq('approved', true);
  
      if (approvedQuotesError) {
        throw new Error(approvedQuotesError.message);
      }
  
      const approvedQuotesTexts = approvedQuotesData.map(quote => quote.text.toLowerCase());
  
      const filteredQuotes = [];
  
      for (const quote of quotesData) {
        const isValidLength = isLengthValid(quote.text);
        const isDuplicate = approvedQuotesTexts.includes(quote.text.toLowerCase());
        const hasExceededLimit = quote.author ? await hasExceededSubmissionLimit(quote.author) : false;
  
        if (isValidLength && !isDuplicate && !hasExceededLimit) {
          filteredQuotes.push(quote);
        } else {
          await rejectQuoteAutomatically(quote.id);
        }
      }
  
      const authorIds = filteredQuotes.map(quote => quote.author).filter(Boolean); // Filtra valores nulos
      let quotesWithUser = [];
  
      if (authorIds.length > 0) {
        const { data: usersData, error: usersError } = await supabase
          .from('users')
          .select('*')
          .in('wallet_address', authorIds);
  
        if (usersError) {
          throw new Error(usersError.message);
        }
  
        const usersMap = usersData.reduce((acc, user) => {
          acc[user.wallet_address] = user;
          return acc;
        }, {});
  
        quotesWithUser = filteredQuotes.map(quote => ({
          ...quote,
          user: usersMap[quote.author] || null,
        }));
      } else {
        quotesWithUser = filteredQuotes;
      }
  
      // Mostrar un nombre genérico para quotes sin author
      quotesWithUser = quotesWithUser.map(quote => ({
        ...quote,
        user: quote.user || { twitter_username: "Wojak-Anon", profile_image: null },
      }));
  
      console.log("Quotes with users:", quotesWithUser);
      setQuotes(quotesWithUser);
    } catch (error) {
      console.error('Error fetching quotes:', error.message);
    }
  };
  
  
  const fetchQueueQuotes = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/pending-quote-count`);
      const result = await response.json();
      setPendingQuotes(result.quotes);
    } catch (error) {
      console.error('Error fetching queue quotes:', error.message);
    }
  };

  const rejectQuoteAutomatically = async (quoteId, exceededLimit) => {
    try {
      const { error } = await supabase
        .from('quotes')
        .delete()
        .eq('id', quoteId);

      if (error) {
        console.error('Error rejecting quote:', error.message);
      } else {
        const reason = exceededLimit ? 'exceeded submission limit' : 'did not meet criteria';
        console.log(`Quote with ID ${quoteId} rejected automatically because it ${reason}.`);
      }
    } catch (error) {
      console.error('Error rejecting quote:', error);
    }
  };

  const approveQuote = async (quoteId) => {
    if (!address || !ADMIN_ADDRESSES.includes(address.toLowerCase())) {
      alert("Only the admin can approve quotes.");
      return;
    }
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log('API URL from environment:', apiUrl);
      if (!apiUrl) {
        console.error('API URL not set in environment variables');
        return;
      }
  
      console.log(`Sending POST request to ${apiUrl}/api/approve-quote with quoteId: ${quoteId}`);
  
      const response = await fetch(`${apiUrl}/api/approve-quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quoteId }),
      });
  
      if (!response.ok) {
        throw new Error('Error approving quote');
      }
  
      const result = await response.json();
      const approvedQuote = result.approvedQuote;
  
      if (!approvedQuote) {
        throw new Error('No approved quote data returned');
      }
  
      console.log('Quote approved:', approvedQuote);
      setQuotes((prevQuotes) => prevQuotes.filter(quote => quote.id !== quoteId));
  
      /*if (!ENABLE_QUEUE) {
        if (ENABLE_TWITTER) {
          await publishToTwitter(approvedQuote.text, approvedQuote.url_slug);
        }
        await generateAndSendTelegramImage(approvedQuote.text, quoteId);
      }*/
  
      fetchPendingQuoteCount(); // Update pending count after approving a quote
    } catch (error) {
      console.error('Error approving quote:', error);
    }
  };

  const approveSelectedQuotes = async () => {
    for (const quoteId of selectedQuotes) {
      await approveQuote(quoteId);
    }
    setSelectedQuotes([]);
  };

  const rejectSelectedQuotes = async () => {
    for (const quoteId of selectedQuotes) {
      await rejectQuote(quoteId);
    }
    setSelectedQuotes([]);
  };

  const approveSelectedQuotesNow = async () => {
    for (const quoteId of selectedQuotes) {
      await approveQuoteNow(quoteId);
    }
    setSelectedQuotes([]);
  };
  
  const approveQuoteNow = async (quoteId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/approve-quote-now`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quoteId }),
      });
  
      if (!response.ok) {
        throw new Error('Error approving quote immediately');
      }
  
      const result = await response.json();
      const approvedQuote = result.approvedQuote;
  
      if (!approvedQuote) {
        throw new Error('No approved quote data returned');
      }
  
      console.log('Quote approved and published immediately:', approvedQuote);
      setQuotes((prevQuotes) => prevQuotes.filter(quote => quote.id !== quoteId));
      fetchPendingQuoteCount(); // Update pending count after approving a quote
    } catch (error) {
      console.error('Error approving quote immediately:', error);
    }
  };
  

  const toggleSelectQuote = (quoteId) => {
    setSelectedQuotes(prevSelectedQuotes =>
      prevSelectedQuotes.includes(quoteId)
        ? prevSelectedQuotes.filter(id => id !== quoteId)
        : [...prevSelectedQuotes, quoteId]
    );
  };

  /*const generateAndSendTelegramImage = async (text, quoteId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log(`Sending POST request to ${apiUrl}/api/telegram-image with text: ${text} and quoteId: ${quoteId}`);

      const response = await fetch(`${apiUrl}/api/telegram-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text, quoteId }),
      });

      if (!response.ok) {
        throw new Error('Error generating or sending image to Telegram');
      }

      const result = await response.json();
      console.log('Generated and sent image to Telegram:', result);
    } catch (error) {
      console.error('Error generating and sending image to Telegram:', error);
    }
  };*/

  const rejectQuote = async (quoteId) => {
    if (!address || !ADMIN_ADDRESSES.includes(address.toLowerCase())) {
      alert("Only the admin can reject quotes.");
      return;
    }

    const { error } = await supabase
      .from('quotes')
      .delete()
      .eq('id', quoteId);

    if (error) {
      console.error('Error rejecting quote:', error.message);
    } else {
      setQuotes((prevQuotes) => prevQuotes.filter(quote => quote.id !== quoteId));
    }
  };


  // Definir la función para verificar si el usuario ha ganado
  const checkLucky = async () => {
    const userAddress = address; // O el método que uses para obtener la dirección del usuario
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-lucky`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress: userAddress }),
      });

      const result = await response.json();
      if (result.success) {
        alert('🎉 Congrats, you won a prize! 🏆'); // Mensaje de éxito
      } else {
        alert('Thank you for participating! Better luck next time!'); // Mensaje de no ganador
      }
    } catch (error) {
      console.error('Error checking lucky status:', error);
    }
  };

  return (
    <div className="main-container">
      <div className="text-carousel-wrapper">
        <h1 className="text-6xl font-bold text-white centered-title carousel-title">
          {isMobile ? 'My Fucking Cryptos' : 'My Fucking Cryptos Admin'}
        </h1>
        <div className="text-carousel text-align-center carousel-subtitle">
          <h3 className="text-4xl font-bold text-white">
            If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
          </h3>
        </div>
      </div>

      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <h3>Pending Queue Stories on Server: {pendingCount}</h3>
      </div>

      <div className="bulk-action-buttons">
        <button className="approve-button-pending" onClick={approveSelectedQuotes}>
          <FaCheck /> Approve Selected
        </button>
        <button className="reject-button-pending" onClick={rejectSelectedQuotes}>
          <FaTimes /> Reject Selected
        </button>
        <button className="approve-now-button" onClick={approveSelectedQuotesNow}>
          <FaBolt /> Approve NOW
        </button>   
        {/* */}<button className="check-lucky-button" onClick={checkLucky}>
          <FaGift />
        </button>                  
      </div>

      <div className="quote-list-container">
        {quotes.map((quote) => (
          <div key={quote.id} className={`quote-card ${selectedQuotes.includes(quote.id) ? 'selected' : ''}`} onClick={() => toggleSelectQuote(quote.id)} style={{height:'550px'}}>
            <div className='ensens' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '0.25em', fontWeight: 'bold', justifyContent:'center', paddingTop:'4.5em' }}>
              {quote.user ? (
                <>
                  <img draggable="false" src={quote.user.profile_image || wallet} alt="wallet" className="button-wallet-ens round-image" loading="lazy" />
                  <span>{quote.user.twitter_username || `Wojak-Anon-${quote.user.id}`}</span>
                </>
              ) : (
                <>
                  <img draggable="false" src={wallet} alt="wallet" className="button-wallet-ens" loading="lazy" />
                  <span>Wojak-Anon</span>
                </>
              )}
            </div>
            <p className="quote-text">{quote.text}</p>
            <div className="quote-footer">
              <button className="approve-button" onClick={(e) => { e.stopPropagation(); approveQuote(quote.id); }}>
                <FaCheck /> Approve
              </button>
              <button className="reject-button" onClick={(e) => { e.stopPropagation(); rejectQuote(quote.id); }}>
                <FaTimes /> Reject
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminQuoteList;