import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAddress } from '@thirdweb-dev/react';
import { BeatLoader } from 'react-spinners';

const ModalFrens = ({ show, onClose }) => {
  const [message, setMessage] = useState('');
  const walletAddress = useAddress(); // Obtén la dirección de la wallet
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true); // Comienza a cargar
      // Hacer la llamada al servidor para ver si ha ganado
      axios.post(`https://wtfserver.xyz:5001/api/check-lucky`, {
        walletAddress // Incluye la dirección de la wallet en el cuerpo de la solicitud
      })
        .then((response) => {
          const { message, image } = response.data;
          setMessage(
            <>
              <span className={response.data.success ? 'congratulations' : 'thank-you'}>
                {message}
              </span><br /><br />
              <img draggable="false" src={`${process.env.PUBLIC_URL}${image}`} alt={response.data.success ? "Wojak Win" : "Wojak Lose"} />
            </>
          );
        })
        .catch((error) => {
          console.error('Error checking if lucky:', error);
        })
        .finally(() => {
          setLoading(false); // Finaliza la carga
        });
    }
  }, [show, walletAddress]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
    <div className="max-w-md p-4 mx-auto text-center text-white bg-black border-2 border-white rounded-lg shadow-lg">
      {loading ? (
        <BeatLoader size={20} color={"#ffffff"} loading={loading} />
      ) : (
        <p className="mb-4 text-4xl">{message}</p>
      )}
      <div className="flex items-center justify-center mb-4" style={{marginTop:'2.5em'}}>
        <span className="mr-5">Powered by</span>
        <a href="https://frenslink.com/" target="_blank" rel="noopener noreferrer">
          <img draggable="false" src={`${process.env.PUBLIC_URL}/frens.svg`} alt="Frens Protocol" className="h-6" />
        </a>
      </div>
      <button
        onClick={onClose}
        className="px-4 py-2 mt-4 text-2xl text-white bg-red-500 rounded hover:bg-red-700">
        CLOSE
      </button>
    </div>
  </div>
  );
};

export default ModalFrens;
