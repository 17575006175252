import React from 'react';
import twitter from './twitter_black.png';
import telegram from './telegram_black.png';
import dexs from './dexs_black.png';
import dext from './dext_black.png';
import uni from './uni_black.png';
import docs from './docs_black.png';
import gitbook from './gitbook_black.png';

const Socials = () => {
  return (
    <div className="socials-container">
      <a href="https://x.com/FuckingCryptos" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
        <img draggable="false" src={twitter} alt="Twitter" title='Twitter / X' className="twitter-button2" />
      </a>
      <a href="https://t.me/myfuckingcryptos" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
        <img draggable="false" src={telegram} alt="Telegram" title='Telegram' className="telegram-button2" />
      </a>

      <a href="https://docs.myfuckingcryptos.com/" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
        <img draggable="false" src={docs} alt="Docs" title='Docs' className="telegram-button2" />
      </a>             

        <img draggable="false" src={dexs} alt="DexScreener" title='DexScreener' className="dexs-button2" style={{opacity:'0.70', cursor:'not-allowed'}} />      

        <img draggable="false" src={dext} alt="DexTools" title='DexTools' className="dext-button2" style={{opacity:'0.70', cursor:'not-allowed'}} />    

        <img draggable="false" src={uni} alt="Uniswap" title='Uniswap' className="uni-button2" style={{opacity:'0.70', cursor:'not-allowed'}} />    


      {/*<a href="https://www.dexscreener.com" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
        <img draggable="false" src={dexs} alt="DexScreener" title='DexScreener' className="dexs-button2" />      
      </a>
      <a href="https://www.dextools.io/app" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
        <img draggable="false" src={dext} alt="DexTools" title='DexTools' className="dext-button2" />
      </a>
      <a href="https://uniswap.org/" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
        <img draggable="false" src={uni} alt="Uniswap" title='Uniswap' className="uni-button2" />
      </a>*/}

    </div>
  );
};

export default Socials;
