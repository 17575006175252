import React, { useEffect, useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import supabase from '../supabaseClient';
import AnimatedCounter from './AnimatedCounter';
import TotalVotes from './TotalUserVotes';
import Socials from './Socials2';
import axios from 'axios';
import airdropinfo from './airdropinfo.png';

const MyReferrals = ({ referralCode }) => {
  const address = useAddress();
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [userData, setUserData] = useState(null); // Aquí declaramos el estado userData
  const [isBoostsLoaded, setIsBoostsLoaded] = useState(false)
  const [boosts, setBoosts] = useState({
    boostHolder: 0,
    boostDex: 0,
    boostTwitter: 0,
    boostStories: 0,
    boostVotesCast: 0,
    boostVotesReceived: 0,
    boostFriends: 0,
    boostStoriesRequired: 0,
    boostVotesCastRequired: 0,
    boostVotesReceivedRequired: 0,
    boostFriendsRequired: 0,
});

// Donde `isHolder` es un nuevo estado para mostrar si el usuario holdea o no tokens
const [isHolder, setIsHolder] = useState(false);  
// Donde `isDexHolder` es un nuevo estado para mostrar si el usuario holdea o no tokens DEXT
const [isDexHolder, setIsDexHolder] = useState(false);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // 10 items per page
  const totalPages = Math.ceil(referrals.length / itemsPerPage);
  const [stats, setStats] = useState({
    totalPoints: 0,
    quotesSubmitted: 0,
    votesCast: 0,
    friendsReferred: 0,
    votesReceived: 0,
    walletVoteCount: 0,
    referralPoints: 0,
    bonusPoints: 0,
  });

  const [requiredStories, setrequiredStories] = useState(0);
  const [requiredVoteCast, setRequiredVoteCast] = useState(0);
  const [requiredVoteReceived, setRequiredVoteReceived] = useState(0);
  const [requiredFriends, setRequiredFriends] = useState(0);
  const [requiredMFCHolding, setrequiredMFCHolding] = useState(0);
  const [requiredDextHolding, setrequiredDextHolding] = useState(0);

    // Obtener la configuración del servidor
    useEffect(() => {
      const fetchConfig = async () => {
        try {
          const response = await axios.get(`https://wtfserver.xyz:5001/api/config`);
          const configData = response.data;
          setrequiredStories(configData.requiredStories);
          setRequiredVoteCast(configData.requiredVoteCast);
          setRequiredVoteReceived(configData.requiredVoteReceived);
          setRequiredFriends(configData.requiredFriends);
          setrequiredMFCHolding(configData.requiredMFCHolding);
          setrequiredDextHolding(configData.requiredDextHolding);
        } catch (error) {
          console.error('Error fetching config:', error);
        }
      };
      fetchConfig();
    }, []); 

  // Obtén datos de usuario en `userData` cuando haya una dirección conectada
    const fetchUserData = async () => {
      if (address) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('wallet_address', address)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          setUserData(data);
        }
      }
    };

  // Llama a esta función después de haber obtenido userData
  /*const checkLucky = async () => {
    if (!address) return;

    try {
      const response = await axios.post(`https://wtfserver.xyz:5001/api/check-lucky`, {
        walletAddress: address,
        twitterHandle: userData?.twitter_handle, // Envía el twitter_handle
        boosts: boosts // Envía los boosts actuales
      });

      // Manejar la respuesta
    } catch (error) {
      console.error('Error checking if user won:', error);
    }
  };*/

// Luego, en el efecto donde llamas a fetchUserData
useEffect(() => {
  if (address) {
    fetchUserData(); // Obtener datos del usuario
    //checkLucky(); // Llama a checkLucky después de que los datos estén listos
  }
}, [address]);  

  useEffect(() => {
    const fetchBoosts = async () => {
      try {
        const response = await fetch('https://wtfserver.xyz:5001/api/boosts', {
        credentials: 'include', // Incluye credenciales solo si es necesario
    });
        
        // Verifica que la respuesta tenga un estado OK (200)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json(); // Asegúrate de convertir la respuesta a JSON
        setBoosts(data);
        setIsBoostsLoaded(true); // Marca como cargados después de obtener los datos
      } catch (error) {
        console.error('Error fetching boosts:', error);
      }
    };
  
    fetchBoosts();
  }, []);

  const checkHolderStatus = async () => {
    if (!address) {
      console.error('No address provided for holder check.');
      return;
    }

    try {
      const response = await fetch('https://wtfserver.xyz:5001/api/check-holders?address=' + address);
      
      // Verifica si la respuesta es HTML (lo que indica un error)
      const contentType = response.headers.get('content-type');
      if (!response.ok || contentType.includes('text/html')) {
        const errorText = await response.text();
        throw new Error(`Unexpected response: ${errorText}`);
      }

      const data = await response.json();
      
    // Actualiza el estado con los resultados obtenidos
    setIsHolder(data.isEthHolder);
    setIsDexHolder(data.isDexHolder);

    // Actualiza también los boosts basados en si es holder o no
    setBoosts(prevBoosts => ({
      ...prevBoosts,
      boostHolder: data.isEthHolder ? prevBoosts.boostHolder : 0,
      boostDex: data.isDexHolder ? prevBoosts.boostDex : 0,
    }));
    
  } catch (error) {
    console.error('Error checking holder status:', error);
  }
};
  // Llama a esta función en el `useEffect` cuando haya una `address` disponible
  useEffect(() => {
    if (address) {
      checkHolderStatus();
    }
  }, [address]);

  useEffect(() => {
    if (referralCode) {
      fetchStats();
      fetchUserName();
      fetchReferrals();
    }
  }, [referralCode]);

  const fetchReferrals = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('referrals_with_quotes')
        .select(`
          *,
          users (
            wallet_address,
            twitter_username,
            profile_image
          )
        `)
        .eq('referral_code', referralCode);

      if (error) {
        throw error;
      }

      if (data.length === 0) {
        console.log('No referrals found');
        setReferrals([]);
      } else {
        const referralUserIds = data.map(referral => referral.user_id);
        const { data: userStats, error: statsError } = await supabase
          .from('user_stats')
          .select('wallet_address, total_points')
          .in('wallet_address', referralUserIds);

        if (statsError) {
          throw statsError;
        }

        const referralsWithPoints = data.map(referral => {
          const userStatsEntry = userStats.find(stats => stats.wallet_address === referral.user_id);
          return {
            ...referral,
            total_points: userStatsEntry ? userStatsEntry.total_points : 0,
          };
        });

        setReferrals(referralsWithPoints);
      }
    } catch (error) {
      console.error('Error fetching referrals:', error.message);
      setReferrals([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    if (!address) {
      console.error('Error: address is null or undefined');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('user_stats')
        .select('*')
        .eq('wallet_address', address)
        .single();

      if (error) {
        throw error;
      }

      setStats(data);
    } catch (error) {
      console.error('Error fetching stats:', error.message);
    }
  };

  const fetchUserName = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('twitter_username')
        .eq('wallet_address', address)
        .single();

      if (error) {
        throw error;
      }

      if (data) {
        setUserName(data.twitter_username || shortenAddress(address));
      } else {
        setUserName(shortenAddress(address));
      }
    } catch (error) {
      console.error('Error fetching user name:', error.message);
    }
  };

  const copyToClipboard = () => {
    const referralUrl = `${window.location.origin}/signup?ref=${referralCode}`;
    navigator.clipboard.writeText(referralUrl);
    alert('Referral link copied to clipboard!');
  };

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 7)}...${address.slice(-5)}`;
  };

  const totalBoosts = () => {
    let total = 0;
  
    // Verifica si el usuario es holder y suma los boosts
    if (boosts.boostHolder > 0 && isHolder) {
      total += boosts.boostHolder;
    }
    if (boosts.boostDex > 0 && isDexHolder) {
      total += boosts.boostDex;
    }
    if (userData?.twitter_handle && boosts.boostTwitter > 0) {
      total += boosts.boostTwitter;
    }
  
    // Verificar requisitos y sumar solo si se cumplen
    if (stats.quotes_submitted >= boosts.boostStoriesRequired) {
      total += boosts.boostStories; // Sumar el boost de historias solo si se cumple el requisito
    }
    if (stats.votes_cast >= boosts.boostVotesCastRequired) {
      total += boosts.boostVotesCast; // Sumar el boost de votos lanzados solo si se cumple el requisito
    }
    if (stats.votes_received >= boosts.boostVotesReceivedRequired) {
      total += boosts.boostVotesReceived; // Sumar el boost de votos recibidos solo si se cumple el requisito
    }
    if (stats.friends_referred >= boosts.boostFriendsRequired) {
      total += boosts.boostFriends; // Sumar el boost de amigos referidos solo si se cumple el requisito
    }
  
    return total; // Retornar el total calculado
  };

  return (
    <><div className="signup-container">
      <div className="text-carousel-wrapper" style={{ background: '#e5e7eb' }}>
        <h1 className="text-6xl font-bold text-black centered-title carousel-title">
          My Fucking Stats!
        </h1>

        <Socials />

        <div className="text-carousel text-align-center carousel-subtitle">
          <h3 className="text-4xl font-bold text-black">
            If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
          </h3>
        </div>
      </div>

      <div className="w-full max-w-6xl mx-auto mb-0 mt-3 text-center bg-white" style={{ paddingLeft: '0.1em', paddingRight: '0.1em', width:'90%' }}>
        <h2 className="text-3xl font-bold text-black" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
          <i>Here’s your fucking stats for the bonus boosts!</i>
                <a href="https://docs.myfuckingcryptos.com/bonus-boosts" target="_blank" rel="noopener noreferrer">
                  <img draggable="false" src={airdropinfo} alt="info" title='More Info' style={{width:'30px', height:'25px', marginLeft:'0.35em'}}/>
                </a>
          </h2>
      </div>      

      <div className="flex items-center justify-center w-full max-w-6xl p-2 mx-auto mt-3 space-x-4 text-center bg-gray-200 border-2 border-black" style={{ width: '95%' }}>
          <img draggable="false" className="wojak-points" src="/wojak-boost.png" alt="Wojak Boost" />
          <div className="text-center">
            <div className="text-4xl font-bold">TOTAL BOOSTS</div>
            {/* Mostrar la suma de los boosts */}
            <div className="text-5xl font-bold mt-2" style={{ color: isBoostsLoaded ? (totalBoosts() > 0 ? 'green' : 'red') : 'black' }}>
              {!isBoostsLoaded ? '-' : totalBoosts() > 0 ? `+${totalBoosts()}%` : '-'}
            </div>
          </div>
          <img draggable="false" className="wojak-points" src="/wojak-boost2.png" alt="Wojak Boost" />
        </div>

      <div className="flex flex-col justify-between w-full max-w-6xl mx-auto md:flex-row pt-6">
        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto mb-6 text-center md:mb-0 md:mr-4" style={{ paddingTop: '2em', paddingBottom: '1.5em', border: '2px solid black', width: '95%', background:'#e5e7eb' }}>
          <img draggable="false" className="mfc-holder" src="/mfc-holder.png" alt="MFC Holder" style={{ paddingBottom: '0.75em' }} />
          <div className="text-3xl font-bold">
            <span className="block">$MFC HOLDER</span>
          </div>
           {/* si holdea mas de X $MFC que aparezca YES en verde, sino NO en rojo */}
           <div className="text-5xl font-bold mt-2">
              {/* Cambia el color basado en el valor de `isHolder` */}
              <span style={{ color: isHolder ? 'green' : 'red' }}>
                {isHolder ? 'YES' : 'NO'}
              </span>
            </div>
            {!isHolder && (
              <div className="text-xl font-bold">Need to be a $MFC holder</div>
            )}               
        </div>

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '2em', paddingBottom: '1.5em', border: '2px solid black', width: '95%', background:'#e5e7eb' }}>
          <img draggable="false" className="mfc-holder" src="/bonus-holder.png" alt="Bonus Holder" style={{ paddingBottom: '0.75em' }} />
          <div className="text-3xl font-bold">
            <span className="block">BOOST HOLDER</span>
          </div>
          <div className="text-5xl font-bold">
              {!isBoostsLoaded ? ( // Si no están cargados
                <span>-</span> // Muestra un guion
              ) : boosts.boostHolder > 0 && isHolder ? ( // Si están cargados y el usuario es holder
                <span style={{ color: 'green' }}>+{boosts.boostHolder}%</span>
              ) : (
                <span style={{ color: 'red' }}>-</span> // Muestra un guion si no es holder
              )}
            </div>
            {isBoostsLoaded && !isHolder && ( // Muestra el mensaje solo si los boosts están cargados
              <div className="text-xl font-bold">Need to hold {requiredMFCHolding} $MFC</div>
            )}
          </div>

          <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '2em', paddingBottom: '1.5em', border: '2px solid black', width: '95%', background:'#e5e7eb' }}>
            <img draggable="false" className="mfc-holder" src="/dext-holder.png" alt="DEXT Holder" style={{ paddingBottom: '0.75em' }} />
            <div className="text-3xl font-bold">
              <span className="block">BOOST $DEXT</span>
            </div>
            <div className="text-5xl font-bold">
              {!isBoostsLoaded ? ( // Si no están cargados
                <span>-</span> // Muestra un guion
              ) : boosts.boostDex > 0 && isDexHolder ? ( // Si están cargados y el usuario es holder
                <span style={{ color: 'green' }}>+{boosts.boostDex}%</span>
              ) : (
                <span style={{ color: 'red' }}>-</span> // Muestra un guion si no es holder
              )}
            </div>
            {isBoostsLoaded && !isDexHolder && ( // Muestra el mensaje solo si los boosts están cargados
              <div className="text-xl font-bold">Need to hold {requiredDextHolding} $DEXT</div>
            )}
          </div>             

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '2em', paddingBottom: '1.5em', border: '2px solid black', width: '95%', background:'#e5e7eb' }}>
          <img draggable="false" className="mfc-holder" src="/bonus-x.png" alt="Bonus X" style={{ paddingBottom: '0.75em' }} />
          <div className="text-3xl font-bold">
            <span className="block">BOOST TWITTER</span>
          </div>
          <div className="text-5xl font-bold">
            {!isBoostsLoaded ? ( // Si los boosts no han sido cargados
              <span>-</span> // Muestra un guion
            ) : userData?.twitter_handle ? ( // Si están cargados y el usuario tiene Twitter
              <span style={{ color: 'green' }}>+{boosts.boostTwitter}%</span>
            ) : (
              <span style={{ color: 'red' }}>-</span> // Muestra un guion si no tiene Twitter
            )}
          </div>
            {!userData?.twitter_handle && (
              <div className="text-xl font-bold">Need to link Twitter account</div>
            )}
        </div>           
      </div>        

      <div className="flex flex-col justify-between w-full max-w-6xl mx-auto md:flex-row pt-8">

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '1.25em', paddingBottom: '1.5em', border: '2px solid black', width: '95%',    background:'#e5e7eb' }}>
            <img draggable="false" className="wojak-points" src="/wokaj-submitted.png" alt="Wojak Stories" style={{ paddingBottom: '0.75em' }} />
            <div className="text-3xl font-bold">
              <span className="block">STORIES SUBMITTED</span>
            </div>
            <div className="text-5xl font-bold"><AnimatedCounter target={stats.quotes_submitted} /></div>
            <div className="text-5xl font-bold">
              {stats.quotes_submitted >= boosts.boostStoriesRequired ? (
                <span style={{ color: 'green' }}>+{boosts.boostStories}%</span>
              ) : (
                <span style={{ color: 'red' }}>-</span>
              )}
            </div>
            <div className="text-xl font-bold">
              {stats.quotes_submitted < boosts.boostStoriesRequired && `Need ${requiredStories} Stories Submitted`}
            </div>
        </div>

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '1.25em', paddingBottom: '1.5em', border: '2px solid black', width: '95%',    background:'#e5e7eb' }}>
            <img draggable="false" className="wojak-points" src="/wokaj-cast.png" alt="Wojak Cast" style={{ paddingBottom: '0.75em' }} />
            <div className="text-3xl font-bold">
              <span className="block">VOTES CAST</span>
            </div>
            <TotalVotes />
            <div className="text-5xl font-bold">
              {stats.votes_cast >= boosts.boostVotesCastRequired ? (
                <span style={{ color: 'green' }}>+{boosts.boostVotesCast}%</span>
              ) : (
                <span style={{ color: 'red' }}>-</span>
              )}
            </div>
            <div className="text-xl font-bold">{stats.votes_cast < boosts.boostVotesCastRequired && `Need ${requiredVoteCast} Votes Cast`}</div>
        </div>        

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '1.25em', paddingBottom: '1.5em', border: '2px solid black', width: '95%',    background:'#e5e7eb' }}>
            <img draggable="false" className="wojak-points" src="/wokaj-received.png" alt="Wojak Received" style={{ paddingBottom: '0.75em' }} />
            <div className="text-3xl font-bold">
              <span className="block">VOTES RECEIVED</span>
            </div>
            <div className="text-5xl font-bold"><AnimatedCounter target={stats.votes_received} /></div>
            <div className="text-5xl font-bold">
              {stats.votes_received >= boosts.boostVotesReceivedRequired ? (
                <span style={{ color: 'green' }}>+{boosts.boostVotesReceived}%</span>
              ) : (
                <span style={{ color: 'red' }}>-</span>
              )}
            </div>
            <div className="text-xl font-bold">{stats.votes_received < boosts.boostVotesReceivedRequired && `Need ${requiredVoteReceived} Votes Received`}</div>
        </div> 

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center md:mb-0 md:ml-4" style={{ paddingTop: '1em', paddingBottom: '1.5em', border: '2px solid black', width: '95%',    background:'#e5e7eb' }}>
            <img draggable="false" className="wojak-points" src="/wokaj-friends.png" alt="Wojak Friends" style={{ paddingBottom: '0.75em' }} />
            <div className="text-3xl font-bold">
              <span className="block">FRENS REFERRED</span>
            </div>
            <div className="text-5xl font-bold"><AnimatedCounter target={stats.friends_referred} /></div>
            <div className="text-5xl font-bold">
              {stats.friends_referred >= boosts.boostFriendsRequired ? (
                <span style={{ color: 'green' }}>+{boosts.boostFriends}%</span>
              ) : (
                <span style={{ color: 'red' }}>-</span>
              )}
            </div>
            <div className="text-xl font-bold">{stats.friends_referred < boosts.boostFriendsRequired && `Need ${requiredFriends} Frens`}</div>
        </div> 

      </div>

    </div>
    </>
  );
};

export default MyReferrals;
