import React, { useContext, useEffect, useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import supabase from '../supabaseClient';
import { FaTrash } from 'react-icons/fa'; // Importa el icono de basura para el botón de eliminar
import QuotesContext from '../QuotesContext';
import moon from './moon2.png';
import rekt from './rekt2.png';
import wallet from './wallet.png';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import './QuoteList.css';

const MyQuotes = () => {
  const address = useAddress();
  const [fingerprint, setFingerprint] = useState(null);
  const { quotes, setQuotes } = useContext(QuotesContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const generateFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprint(result.visitorId);
    };

    generateFingerprint();
  }, []);

  useEffect(() => {
    const fetchQuotes = async () => {
      const { data, error } = await supabase
        .from('quotes')
        .select('*')
        .eq('author', address);

      if (error) {
        console.error('Error fetching quotes:', error.message);
      } else {
        setQuotes(data);
        setLoading(false);
      }
    };

    if (address) {
      fetchQuotes();
    }
  }, [address, setQuotes]);

  const handleVote = async (quoteId, col) => {
    if (!fingerprint) {
      console.error('Fingerprint not available.');
      return;
    }

    const quote = quotes.find(q => q.id === quoteId);

    if (quote.voted_users.includes(fingerprint)) {
      console.log('El usuario ya ha votado esta Quote');
      return;
    }

    try {
      const { data, error } = await supabase
        .rpc('increment_votes', { row_id: quoteId, col });

      if (error) {
        console.error('Error al votar:', error.message);
      } else {
        console.log('Voto añadido a Supabase', data);
        setQuotes((prevQuotes) =>
          prevQuotes.map((quote) =>
            quote.id === quoteId ? { ...quote, [col]: quote[col] + 1, voted_users: [...quote.voted_users, fingerprint] } : quote
          )
        );

        const { error: updateError } = await supabase
          .from('quotes')
          .update({ voted_users: [...quote.voted_users, fingerprint] })
          .eq('id', quoteId);

        if (updateError) {
          console.error('Error saving vote:', updateError.message);
        }
      }
    } catch (error) {
      console.error('Error al votar:', error.message);
    }
  };

  const handleDelete = async (quoteId) => {
    try {
      const { data, error } = await supabase
        .from('quotes')
        .delete()
        .eq('id', quoteId)
        .eq('author', address);

      if (error) {
        console.error('Error deleting quote:', error.message);
      } else {
        setQuotes((prevQuotes) => prevQuotes.filter((quote) => quote.id !== quoteId));
        console.log('Quote deleted from Supabase', data);
      }
    } catch (error) {
      console.error('Error deleting quote:', error.message);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="main-container">
      <div className="text-carousel-wrapper" style={{ marginBottom: '2em' }}>
        <h1 className="text-6xl font-bold text-white centered-title carousel-title">
          My Fucking Cryptos: Your Crypto Stories Matter
        </h1>
        <div className="text-carousel text-align-center">
          <h3 className="text-4xl font-bold text-white">
            If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
          </h3>
        </div>
      </div>
      <div className="quote-list-container-main">
        {quotes.map((quote) => {
          const voteDifference = quote.moon_votes - quote.rekt_votes;
          let borderColor = 'blue';
          if (voteDifference > 0) borderColor = 'green';
          if (voteDifference < 0) borderColor = 'red';

          const hasVoted = quote.voted_users.includes(fingerprint);

          return (
            <div key={quote.id} className="quote-card">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '0.5em', paddingTop: '0.5em', fontWeight: 'bold' }}>
                <img draggable="false" src={wallet} alt="wallet" className="button-wallet-ens" />ENS HERE OR ADDRESS
              </div>
              {quote.imageUrl ? (
                <div className="quote-image-background">
                  <img draggable="false" src={quote.imageUrl} alt="Quote" className="quote-image" />
                </div>
              ) : (
                <p className="quote-text" style={{ border: `3px solid ${borderColor}`, userSelect: 'none' }}>{quote.text}</p>
              )}
              <div className="quote-footer">
                <div className={`vote-option ${hasVoted ? 'voted' : ''}`} onClick={() => handleVote(quote.id, 'moon_votes')}>
                  <img draggable="false" src={moon} alt="moon" className="button-moon" />
                  <span className='votes-moon-counter'>{quote.moon_votes || 0}</span>
                </div>
                <div className={`vote-option ${hasVoted ? 'voted' : ''}`} onClick={() => handleVote(quote.id, 'rekt_votes')}>
                  <img draggable="false" src={rekt} alt="rekt" className="button-rekt" />
                  <span className='votes-rekt-counter'>{quote.rekt_votes || 0}</span>
                </div>
                <div className="delete-option" onClick={() => handleDelete(quote.id)}>
                  <FaTrash className="delete-icon" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyQuotes;