import React, { useContext, useEffect, useState } from 'react';
import { MusicContext } from '../MusicContext';
import './QuoteList.css';
import Socials from './Socials';
import comingsoon from './coming-soon-ads.jpg';

const Ranking = ({ onEnter, showWelcome, setShowWelcome }) => {
  const { togglePlayPause } = useContext(MusicContext);
  const [bounceOut, setBounceOut] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEnterClick = () => {
    setBounceOut(true);
    setTimeout(() => {
      setShowWelcome(false);
      togglePlayPause();
      if (onEnter) {
        onEnter();
      }
    }, 1000);
  };

  return (
    <div className="main-container">
      {showWelcome ? (
        <div className={`welcome-container ${bounceOut ? 'bounce-out' : ''}`}>
          <button onClick={handleEnterClick}>
            <img id="hover08" draggable="false" src={isMobile ? "/intro-mobile.png" : "/intro.png"} alt="intro" className="intro-image" />
          </button>
        </div>
      ) : (
        <>
          <div className="text-carousel-wrapper" style={{ background: 'black' }}>
            <h1 className="text-6xl font-bold text-white centered-title carousel-title">
              {isMobile ? 'My Fucking Cryptos' : 'My Fucking Cryptos Ads'}
            </h1>
  
            <Socials />
  
            <div className="text-carousel text-align-center carousel-subtitle">
              <h3 className="text-4xl font-bold text-white">
                If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
              </h3>
            </div>
          </div>
  
          <div className="main-container">
            <span style={{paddingTop:'15px', paddingBottom:'15px', fontSize:'3em', textAlign:'center', lineHeight:'initial'}}>
            {isMobile ? (
                <>
                  Advertisement<br />
                  Coming Soon
                </>
              ) : 'Advertisement Coming Soon'}
            </span>
            <img 
              className="advertisement-image"
              draggable="false" 
              src={comingsoon} 
              alt="Coming Soon Ads" 
              loading="lazy" 
              style={{
                width: isMobile ? '350px' : '700px', // Cambia el ancho según el dispositivo
                marginBottom: '1.75em',
                border: '2px solid black',
                filter: 'blur(5px)'
              }}
            />
            <span style={{paddingTop:'0px', paddingBottom:'25px', fontSize:'1.85em', textAlign:'center', lineHeight:'initial'}}>
              Hey Mfer! Interested in promoting your token / meme / shitcoin on MFC?<br/>
              👉🏻 <a href="https://docs.myfuckingcryptos.com/advertisement" target="_blank" rel="noopener noreferrer">
               Hit this link for more info 
              </a> 👈🏻
            </span>            
          </div>
        </>
      )}
    </div>
  );
}

export default Ranking;
