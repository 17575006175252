import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../supabaseClient';
import moon from './moon2.png';
import rekt from './rekt2.png';
import wallet from './wallet.png';
import { TwitterShareButton, TelegramShareButton } from 'react-share';
import '../../src/App.css';
import wojakshare from './wojak-share.png';
import twitter from './twitter.png';
import telegram from './telegram.png';
import download from './download.png';
import axios from 'axios';
import QuotesContext from '../QuotesContext';
import Modal from './Modal';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ethers } from 'ethers';
import AnimatedCounter from './AnimatedCounter';
import { determineBackgroundClass } from './backgroundUtils';
import { toPng } from 'html-to-image';
import ModalFrens from './ModalFrens';

// Helper function to get a random name and image from the predefined list
const getRandomNameAndImage = (predefinedNames) => {
  const randomIndex = Math.floor(Math.random() * predefinedNames.length);
  const selectedName = predefinedNames[randomIndex];
  return {
    name: selectedName.name,
    imageUrl: `${process.env.REACT_APP_API_URL}${selectedName.imageUrl}` // Aseguramos que la URL sea completa
  };
};

const getFingerprint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

const QuoteDetail = () => {
  const { slug } = useParams();
  const [quote, setQuote] = useState(null);
  const { quotes, setQuotes } = useContext(QuotesContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [modalContent, setModalContent] = useState('');
  const [votedQuotes, setVotedQuotes] = useState({});
  const [userCache, setUserCache] = useState({});
  const [quotesWithUser, setQuotesWithUser] = useState([]);
  const [walletAddress, setWalletAddress] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [fingerprint, setFingerprint] = useState(null);
  const [predefinedNames, setPredefinedNames] = useState([]);
  const [assignedNames, setAssignedNames] = useState({});
  const [showThanksBox, setShowThanksBox] = useState({}); // Estado para controlar el cuadro de agradecimiento
  const [showModalFrens, setShowModalFrens] = useState(false);
  const [showThanks, setShowThanks] = useState(false);

  // Fetch predefined names on component mount
  useEffect(() => {
    const fetchPredefinedNames = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/names`);
        setPredefinedNames(response.data);
      } catch (error) {
        console.error('Error fetching predefined names:', error);
      }
    };

    fetchPredefinedNames();
  }, []);

  // Fetch IP address and stored votes on component mount
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ip = response.data.ip;
        setIpAddress(ip);

        const storedVotedQuotes = JSON.parse(localStorage.getItem(ip) || '{}');
        setVotedQuotes(storedVotedQuotes);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModalFrens(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);   

  // Update mobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch wallet address using ethers.js
  useEffect(() => {
    const fetchWalletAddress = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const checksumAddress = ethers.utils.getAddress(accounts[0]);
          setWalletAddress(checksumAddress);
        } catch (error) {
          console.error('Error fetching wallet address:', error.message);
        }
      }
    };

    fetchWalletAddress();
  }, []);

  // Fetch fingerprint for tracking user votes
  useEffect(() => {
    const fetchFingerprint = async () => {
      const fp = await getFingerprint();
      setFingerprint(fp);
    };

    fetchFingerprint();
  }, []);

  // Fetch user data for wallet addresses
  const fetchUserData = async (walletAddresses) => {
    const uncachedAddresses = walletAddresses.filter(addr => addr && !userCache[addr]);
    if (uncachedAddresses.length === 0) return userCache;

    const { data: usersData, error: usersError } = await supabase
      .from('users')
      .select('*')
      .in('wallet_address', uncachedAddresses);

    if (usersError) {
      console.error(usersError);
      return userCache;
    }

    const newUserCache = { ...userCache };
    usersData.forEach(user => {
      newUserCache[user.wallet_address] = user;
    });

    setUserCache(newUserCache);
    return newUserCache;
  };

  // Fetch quote details and associated user data
  useEffect(() => {
    const fetchQuote = async () => {
      const { data, error } = await supabase
        .from('quotes')
        .select('*')
        .eq('url_slug', slug)
        .eq('published', true)
        .single();

      if (error) {
        console.error('Error fetching quote:', error.message);
      } else {
        const userData = await fetchUserData([data.author]);

        // Assign random names to users without twitter_username
        const newAssignedNames = {};
        let user = userData[data.author] || { twitter_username: null, profile_image: wallet, id: data.author };
        if (!user.twitter_username) {
          if (!assignedNames[user.id] && predefinedNames.length > 0) {
            const randomNameData = getRandomNameAndImage(predefinedNames);
            newAssignedNames[user.id] = { name: randomNameData.name + user.id, imageUrl: randomNameData.imageUrl };
          }
          user.twitter_username = newAssignedNames[user.id]?.name || assignedNames[user.id]?.name;
          user.profile_image = newAssignedNames[user.id]?.imageUrl || assignedNames[user.id]?.imageUrl;
        }

        setAssignedNames(prev => ({ ...prev, ...newAssignedNames }));
        setQuote({ ...data, user });
      }
    };

    fetchQuote();
  }, [slug, predefinedNames]);

  // Fetch votes for the current user
  useEffect(() => {
    const fetchVotes = async () => {
      if (!ipAddress || (!walletAddress && !fingerprint)) {
        return;
      }

      let userId = walletAddress || fingerprint;

      try {
        const { data: existingVotes, error: fetchError } = await supabase
          .from('user_votes')
          .select('*')
          .or(`user_address.eq.${userId},ip_address.eq.${ipAddress}`);

        if (fetchError) {
          console.error('Error fetching user votes:', fetchError.message);
          return;
        }

        if (!existingVotes || existingVotes.length === 0) {
          console.log('No votes found for user.');
          return;
        }

        const votesMap = {};
        existingVotes.forEach(vote => {
          votesMap[vote.quote_id] = {
            userId: vote.user_address,
            ipAddress: vote.ip_address,
            col: vote.vote_type
          };
        });

        setVotedQuotes(votesMap);
      } catch (error) {
        console.error('Error fetching user votes:', error.message);
      }
    };

    fetchVotes();
  }, [ipAddress, walletAddress, fingerprint]);

    // Obtener la configuración del servidor
    useEffect(() => {
      const fetchConfig = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/config`);
          setShowThanks(response.data.showThanks); // Asignar showThanks desde el servidor
        } catch (error) {
          console.error('Error fetching config:', error);
        }
      };
      fetchConfig();
    }, []);  

  // Handle voting for a quote
  const handleVote = (quoteId, col) => {
    if (!ipAddress) {
      console.error('IP address not available.');
      return;
    }
  
    let userId = walletAddress || fingerprint;
  
    // Actualizar el estado visualmente de inmediato sin esperar respuesta del servidor
    setQuote(prevQuote => ({
      ...prevQuote,
      [col]: prevQuote[col] + 1
    }));
  
      // Comprobar si el cuadro de agradecimiento está habilitado desde el servidor antes de mostrarlo
      if (showThanks) {
        setShowThanksBox((prev) => ({ ...prev, [quoteId]: true }));
      }
  
    // Enviar la solicitud sin bloquear el flujo
    fetch(`${process.env.REACT_APP_API_URL}/api/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ quoteId, userId, ipAddress, voteType: col }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Error voting');
          });
        }
        return response.json();
      })
      .then((voteData) => {
        console.log('Vote added to Supabase', voteData);
  
        setVotedQuotes((prevVotedQuotes) => ({
          ...prevVotedQuotes,
          [quoteId]: { userId, col }
        }));
      })
      .catch((error) => {
        console.error('Error voting:', error.message);
  
        // Revertir el cambio visual si hay error en el servidor
        setQuote(prevQuote => ({
          ...prevQuote,
          [col]: prevQuote[col] - 1
        }));
        if (showThanks) {
          setShowThanksBox((prev) => ({ ...prev, [quoteId]: false })); // Ocultar el mensaje si hay error
        }
      });
  };
  

  const handleCheckClick = (quote) => {
    setModalContent(`You voted for ${quote.text}`);
    setShowModalFrens(true);
    setShowThanksBox({ ...showThanksBox, [quote.id]: false });
  };

  const handleCloseModalFrens = () => {
    setShowModalFrens(false);
    setModalContent(null);
  };

  if (!quote) {
    return null;
  }

  // URLs for sharing
  const baseUrl = process.env.REACT_APP_LOCALHOST_LIVE;
  const shareUrlTwitter = `${quote.text}`;
  const shareUrlTelegram = `🟢 Vote here Mfer 🔴\n${baseUrl}/${quote.url_slug}`;
  const shareTitleTelegram = `${quote.text}\n\n${shareUrlTelegram}`;
  // Check if user has voted
  const hasVoted = votedQuotes[quote.id] && (
    votedQuotes[quote.id].userId === walletAddress ||
    votedQuotes[quote.id].userId === fingerprint ||
    votedQuotes[quote.id].ipAddress === ipAddress
  );

  const downloadImage = async () => {
    const node = document.querySelector('.quote-card2');
    const footerShare = document.querySelector('.quote-footer-share');
    const ensens = document.querySelector('.ensens');

    // Guardar la altura original
    const originalHeight = node.style.height;

    // Ajustar la altura del contenedor
    node.style.height = `calc(${node.clientHeight}px - 1.8em)`;

    // Establecer padding-top temporal para .ensens
    ensens.style.paddingTop = '3.5em';

    // Oculta el elemento .quote-footer-share
    footerShare.style.display = 'none';
    
    // Establece un fondo blanco temporalmente
    const originalBackground = node.style.backgroundColor;
    const originalPaddingTop = ensens.style.paddingTop;
    node.style.backgroundColor = 'transparent';
    
    try {
      const dataUrl = await toPng(node);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${slug}.png`; // Usa el slug de la URL como nombre de archivo
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      // Vuelve a mostrar el elemento .quote-footer-share y restaura el fondo original
      footerShare.style.display = 'flex';
      node.style.backgroundColor = originalBackground;
      // Restaurar la altura original
      node.style.height = originalHeight;
      ensens.style.paddingTop = '2em';
    }
};


  return (
    <>       
      <div className="text-carousel-wrapper">
        <h1 className="text-6xl font-bold text-white centered-title carousel-title">
          {isMobile ? 'My Fucking Cryptos' : 'My Fucking Cryptos: Your Crypto Stories Matter'}
        </h1>
        <div className="text-carousel text-align-center carousel-subtitle">
          <h3 className="text-4xl font-bold text-white">
            If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
          </h3>
        </div>
      </div>
      
      <div style={{ display: 'flex', flexDirection:'column', width: '100%', height: 'fit-content', alignItems: 'center', justifyContent: 'center', marginTop:'1.5em', marginBottom:'0em' }}>
        <div className={`quote-card2 ${determineBackgroundClass(quote.text)}`}>
          <div className='ensens' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '0.25em', paddingTop: '2em', fontWeight: 'bold', justifyContent: 'center' }}>
            {quote.user && quote.user.twitter_username ? (
              // Envolver la imagen y el nombre en un enlace externo a Twitter
              <a href={`https://twitter.com/${quote.user.twitter_handle}`} target="_blank" rel="noopener noreferrer" style={{ color:'black', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                <img draggable="false" src={quote.user.profile_image} alt="wallet" className="button-wallet-ens round-image" loading="lazy" />
                <span>{quote.user.twitter_username}</span>
              </a>
            ) : (
              <>
                <img draggable="false" src={quote.user.profile_image || wallet} alt="wallet" className="button-wallet-ens" loading="lazy" />
                <span>{assignedNames[quote.user.id]?.name || `Wojak-Anon-${quote.user.id}`}</span>
              </>
            )}
          </div>

          {quote.imageUrl ? (
              <div className="quote-image-background">
                <img draggable="false" src={quote.imageUrl} alt="Quote" className="quote-image" loading="lazy" />
              </div>
            ) : (
              <div>
                <p className="quote-text" style={{ border: `3px solid black`, userSelect: 'none', marginBottom: '10px', display:'flex', flexDirection:'column', marginBottom:'0px', borderBottom:'0px', height:'310px'}}>
                  {quote.text}
                </p>
                <p href="https://myfuckingcryptos.com" style={{ background:'#ffffffd4' ,color: 'black', fontSize:'1.5em', border: `3px solid black`, borderTop:'0px', padding:'3px' }}>
                    my<span style={{color:'red'}}>fuck</span>ingcryptos.com
                </p>    
              </div>
            )}

          <div className="quote-footer">
            {/* Mostrar agradecimiento después del voto */}
            {hasVoted && showThanksBox[quote.id] && (
              <div className={`vote-thanks ${votedQuotes[quote.id].col === 'moon_votes' ? 'moon' : 'rekt'}`}>
                <p>Thank you for your Vote! <span className='check-lucky' onClick={() => handleCheckClick(quote)} style={{ cursor: 'pointer' }}>CHECK YOUR LUCKY 🤞</span></p>
              </div>
            )}

            <div 
              className="vote-option" 
              onClick={() => !hasVoted && handleVote(quote.id, 'moon_votes')}
              style={{ pointerEvents: hasVoted ? 'none' : 'auto' }}
            >
              <img className={`vote-option ${hasVoted ? 'voted' : ''} button-moon`} draggable="false" src={moon} alt="moon" style={{ opacity: hasVoted ? 0.3 : 1 }} />
              <span className='votes-moon-counter'><AnimatedCounter target={quote.moon_votes || 0} /></span>
            </div>
            <div 
              className="vote-option" 
              onClick={() => !hasVoted && handleVote(quote.id, 'rekt_votes')}
              style={{ pointerEvents: hasVoted ? 'none' : 'auto' }}
            >
              <img className={`vote-option ${hasVoted ? 'voted' : ''} button-rekt`} draggable="false" src={rekt} alt="rekt" style={{ opacity: hasVoted ? 0.3 : 1 }} />
              <span className='votes-rekt-counter'><AnimatedCounter target={quote.rekt_votes || 0} /></span>
            </div>
          </div>
        </div>
        <div className="quote-footer-share">
            <img draggable="false" src={wojakshare} alt="Share" className="wojak-share-button" />
            <div className="share-buttons">
              Share it Mfer!
              <TwitterShareButton url={shareUrlTwitter} style={{ marginLeft: '0.5em', marginRight: '0.5em', position:'relative', top:'-5px' }}>
                <img draggable="false" src={twitter} alt="Twitter" title='Twitter / X' className="twitter-button" />
              </TwitterShareButton>
              <TelegramShareButton url={shareTitleTelegram} style={{ position:'relative', top:'-5px' }}>
                <img draggable="false" src={telegram} alt="Telegram Channel" title='Telegram' className="twitter-button" />
              </TelegramShareButton>
              <button onClick={downloadImage} style={{ position:'relative', top:'-5px' }}>
                <img draggable="false" src={download} alt="Download" title='Download' className="twitter-button" />
              </button>                          
            </div> 
          </div>        
      </div>      
      <ModalFrens show={showModalFrens} onClose={handleCloseModalFrens}>
        {modalContent}
      </ModalFrens>      
    </>
  );
};

export default QuoteDetail;
