import React, { useContext, useEffect, useState, useRef } from 'react';
import { MusicContext } from '../MusicContext';
import './QuoteList.css';
import Socials from './Socials';
import gorra from './mfc_gorra.png';
import gorra_base from './pfp_base.jpg'; // Importar la imagen base
import gorra2 from './mfc_gorra2.png';
import gorra3 from './mfc_gorra3.png';
import gorra4 from './mfc_gorra4.png';
import gorra5 from './mfc_gorra5.png';
import gorra6 from './mfc_gorra6.png';
import { Stage, Layer, Image as KonvaImage, Transformer } from 'react-konva';
import useImage from 'use-image';

const Pfp = ({ onEnter, showWelcome, setShowWelcome }) => {
  const { togglePlayPause } = useContext(MusicContext);
  const [bounceOut, setBounceOut] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [baseImage] = useImage(gorra_base); // Imagen base inicial
  const [uploadedImage, setUploadedImage] = useState(null); // Para la imagen subida por el usuario
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedHat, setSelectedHat] = useState(null); // Gorra seleccionada
  const [selectedShape, setSelectedShape] = useState(null); // Para la selección de la gorra

  const stageRef = useRef(null);
  const hatRef = useRef(null);
  const trRef = useRef(null); // Referencia para el Transformer
  const [hatImage] = useImage(selectedHat); // Cargar la gorra seleccionada con useImage

  useEffect(() => {
    if (selectedShape && trRef.current) {
      trRef.current.nodes([hatRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedShape]);

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = (event) => {
      const img = new window.Image();
      img.src = event.target.result;
      img.onload = () => {
        setUploadedImage(img); // Establecer la imagen subida
        setImageLoaded(true);
      };
    };

    reader.readAsDataURL(file);
  };

  const downloadImage = () => {
    // Desselecciona la gorra temporalmente para ocultar el Transformer
    setSelectedShape(null);

    // Espera un pequeño retardo para asegurarte de que el Transformer está oculto antes de generar la imagen
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const link = document.createElement('a');
      link.href = uri;
      link.download = 'mfc_new_pfp.png';
      link.click();
    }, 100);
  };

  const imageToRender = uploadedImage || baseImage; // Usa la imagen subida o la imagen base

  return (
    <div className="main-container">
      {showWelcome ? (
        <div className={`welcome-container ${bounceOut ? 'bounce-out' : ''}`}>
          <button onClick={handleEnterClick}>
            <img id="hover08" draggable="false" src={isMobile ? "/intro-mobile.png" : "/intro.png"} alt="intro" className="intro-image" />
          </button>
        </div>
      ) : (
        <>
          <div className="text-carousel-wrapper" style={{ background: '#dc143c' }}>
            <h1 className="text-6xl font-bold text-white centered-title carousel-title">
              {isMobile ? 'My Fucking Cryptos' : 'My Fucking Cryptos PFP Generator'}
            </h1>

            <Socials />

            <div className="text-carousel text-align-center carousel-subtitle">
              <h3 className="text-4xl font-bold text-white">
                If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
              </h3>
            </div>
          </div>

          <div className="main-container">
            <div className="referrals-table-container">
              <h2 className="text-3xl font-bold text-black centered-title md:text-4xl" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                MFC PFP Generator
              </h2>

              <p className="text-2xl text-black centered-title mb-4"><i>Create your own My Fucking Cryptos Profile Photo to flex on Telegram or Twitter!</i></p>

              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <label htmlFor="file-upload" className="custom-file-label">
                  👉🏻 Upload your Image 👈🏻
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="custom-file-input"
                />
              </div>

              {/* Selección de Gorras: solo después de subir una imagen */}
              {imageLoaded && (
                      <div 
                        style={{
                          display: 'grid', 
                          gridTemplateColumns: isMobile ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)', 
                          gap: '10px', 
                          justifyItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '20px'
                        }}
                      >
                        <img 
                          src={gorra} 
                          alt="Hat 1" 
                          style={{ width: '90px', cursor: 'pointer' }} 
                          onClick={() => setSelectedHat(gorra)} 
                        />
                        <img 
                          src={gorra3} 
                          alt="Hat 2" 
                          style={{ width: '90px', cursor: 'pointer' }} 
                          onClick={() => setSelectedHat(gorra3)} 
                        />
                        <img 
                          src={gorra2} 
                          alt="Hat 3" 
                          style={{ width: '90px', cursor: 'pointer' }} 
                          onClick={() => setSelectedHat(gorra2)} 
                        />
                        <img 
                          src={gorra4} 
                          alt="Hat 4" 
                          style={{ width: '90px', cursor: 'pointer' }} 
                          onClick={() => setSelectedHat(gorra4)} 
                        />
                        <img 
                          src={gorra5} 
                          alt="Hat 5" 
                          style={{ width: '90px', cursor: 'pointer' }} 
                          onClick={() => setSelectedHat(gorra5)} 
                        />
                        <img 
                          src={gorra6} 
                          alt="Hat 6" 
                          style={{ width: '100px', cursor: 'pointer' }} 
                          onClick={() => setSelectedHat(gorra6)} 
                        />                        
                      </div>
                    )}
        

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {imageToRender && (
                  <Stage width={isMobile ? window.innerWidth * 0.9 : imageToRender.width}  // Ajustar el ancho al tamaño de la pantalla en móvil
                  height={isMobile ? (imageToRender.height / imageToRender.width) * (window.innerWidth * 0.9) : imageToRender.height} ref={stageRef} style={{ border: '4px solid #dc143c', maxWidth: '100%', height: 'auto' }}>
                    <Layer>
                      {/* Imagen de fondo */}
                      <KonvaImage
                        image={imageToRender}
                        width={isMobile ? window.innerWidth * 0.9 : imageToRender.width}  // Ajustar el ancho en móvil
                        height={isMobile ? (imageToRender.height / imageToRender.width) * (window.innerWidth * 0.9) : imageToRender.height}  // Mantener relación de aspecto
                      />
                      {/* Gorra interactiva */}
                      {hatImage && imageLoaded && (
                          <KonvaImage
                            image={hatImage}
                            x={imageToRender.width / 2 - (hatImage.width * 0.5) / 2}  // Centrar en el eje X
                            y={imageToRender.height * 0.1}  // Colocar un poco hacia arriba en el eje Y
                            draggable
                            scaleX={0.5}  // Hacer la gorra más grande
                            scaleY={0.5}  // Hacer la gorra más grande
                            ref={hatRef}
                            onClick={() => setSelectedShape(hatRef.current)} // Selecciona la gorra al hacer click
                            onTap={() => setSelectedShape(hatRef.current)}
                            onDragEnd={(e) => {
                              console.log('Posición:', e.target.x(), e.target.y());
                            }}
                            onTransformEnd={(e) => {
                              const node = e.target;
                              const scaleX = node.scaleX();
                              const scaleY = node.scaleY();
                              node.scaleX(1); // Resetea la escala para que no se acumule
                              node.scaleY(1);
                              node.width(node.width() * scaleX);
                              node.height(node.height() * scaleY);
                              console.log('Nueva escala:', scaleX, scaleY);
                            }}
                          />
                        )}
                      {selectedShape && (
                        <Transformer
                          ref={trRef}
                          boundBoxFunc={(oldBox, newBox) => {
                            // Limita el tamaño mínimo de la gorra
                            if (newBox.width < 50 || newBox.height < 50) {
                              return oldBox;
                            }
                            return newBox;
                          }}
                        />
                      )}
                    </Layer>
                  </Stage>
                )}
              </div>

              {imageLoaded && (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <button className="btn btn-primary button-gorra" onClick={downloadImage} style={{fontSize:'1.4em', borderRadius:'0', marginTop:'0.5em', background:'#dc143c', border:'none'}}>
                    Download PFP
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Pfp;
